<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="120px" size="small">
    <el-row>
      <el-col :span="24">
        <el-form-item label="被执行人序号" prop="num">
          <el-input type="text" v-model="form.num"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="被执行人名称" prop="name">
          <el-input type="text" v-model="form.name"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm()"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        num: [{ required: true, message: '请填写被执行人序号', trigger: 'blur' }],
        name: [{ required: true, message: '请填写呗执行人名称', trigger: 'blur' }]
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.form)
          this.form = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('resetForm')
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped></style>
