<template>
  <div>
    <div>
      <el-table
        :data="tableData"
        border
        stripe
        show-summary
        :summary-method="getSum"
        :header-row-style="TableHeaderRowStyle"
        :header-cell-style="TableHeaderCellStyle"
        :row-style="TableRowStyle"
        :cell-style="TableCellStyle"
      >
        <el-table-column
          align="center"
          prop="huikuan_date"
          label="回款日期"
          min-width="30%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="huikuan_amount"
          label="回款金额（元)"
          min-width="10%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="qiankuan_amount"
          label="尚欠款"
          min-width="40%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="yifan_cailiao"
          label="已返材料"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="weifan_cailiao"
          label="剩余未返材料"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="30%">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              @click="handleEdit(scope.row)"
              v-if="userType.code === '3'"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              v-if="canDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import utils from '../../assets/js/utils'

export default {
  props: {
    tableData: Array,
    userType: Object
  },
  computed: {
    canDelete() {
      return function() {
        if (this.userType.id === 1 || this.userType.id === 3) return true
        else return false
      }
    }
  },
  methods: {
    //设置表格行的样式
    //如果要设置不同的行不同风格，则通过{row,rowIndex}设置， tableRowStyle({row,rowIndex})
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    getSum(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
        if (column.property === 'huikuan_amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
          // sums[index] += sums[index];sums[index];
        }

        // else if (column.property === 'qiankuan_amount') {
        //   sums[index] = values.reduce((prev, curr) => {
        //     const value = Number(curr)
        //     if (!isNaN(value)) {
        //       return prev + curr
        //     } else {
        //       return prev
        //     }
        //   }, 0)
        //   // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
        //   // sums[index] += sums[index];sums[index];
        // }
        else {
          sums[index] = '--'
        }
      })
      return sums
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },
    //修改
    handleEdit(row) {
      console.log('huikuan row:', row)
      this.$emit('edit', row)
    }
  }
}
</script>
