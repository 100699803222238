<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="100px" size="small">
    <el-row>
      <el-col :span="24">
        <el-form-item label="判决总金额" prop="panjue_amount">
          <el-input type="number" step="0.01" v-model="form.panjue_amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :rows="4" v-model="form.remark"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm()"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        panjue_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }]
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.form)
          this.form = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('resetForm')
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped></style>
