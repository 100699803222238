<template>
  <div>
    <div>
      <el-table
        :data="tableData"
        border
        stripe
        show-summary
        :summary-method="getSum"
        :header-row-style="TableHeaderRowStyle"
        :header-cell-style="TableHeaderCellStyle"
        :row-style="TableRowStyle"
        :cell-style="TableCellStyle"
      >
        <el-table-column align="center" prop="panjue_amount" label="判决总金额" min-width="30%"> </el-table-column>
        <el-table-column align="center" prop="remark" label="备注" min-width="10%"> </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  props: {
    tableData: Array,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.id === 1) return true
        else {
          let added_person_time = utils.stringToDate(row.added_person_time)
          let now = new Date()
          let interval = now.getTime() - added_person_time.getTime()
          interval = interval / 1000 / 60 / 60
          console.log('addedtime:', added_person_time)
          console.log('now:', now)
          console.log('del..........', interval)
          if (interval > 2) return false
          else return true
        }
      }
    }
  },
  methods: {
    //设置表格行的样式
    //如果要设置不同的行不同风格，则通过{row,rowIndex}设置， tableRowStyle({row,rowIndex})
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    getSum(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
        if (column.property === 'panjue_amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
          // sums[index] += sums[index];sums[index];
        } else if (column.property === 'qiankuan_amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
          // sums[index] += sums[index];sums[index];
        } else {
          sums[index] = '--'
        }
      })
      return sums
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    }
  }
}
</script>
