<template>
  <div id="container">
    <!-- dialogs -->
    <!-- 判决 增加 -->
    <el-dialog width="700px" title="新增判决信息" :visible.sync="zxPanjue_isShow" :close-on-click-modal="false">
      <ZxFawuPanjueForm ref="fawuPanjueForm" @submitForm="submitPanjueForm" @resetForm="resetPanjueForm"></ZxFawuPanjueForm>
    </el-dialog>
    <!-- 被执行人 增加 -->
    <el-dialog width="700px" title="新增被执行人信息" :visible.sync="zxBeizhixingren_isShow" :close-on-click-modal="false">
      <ZxFawuBeizhixingrenForm ref="fawuBeizhixingrenForm" @submitForm="submitBeizhixingrenForm" @resetForm="resetBeizhixingrenForm"></ZxFawuBeizhixingrenForm>
    </el-dialog>
    <!-- 执行进度 增加 -->
    <el-dialog width="700px" title="新增执行进度信息" :visible.sync="zxZhixingjindu_isShow" :close-on-click-modal="false">
      <ZxFawuZhixingjinduForm ref="fawuZhixingjinduForm" @submitForm="submitZhixingjinduForm" @resetForm="resetZhixingjinduForm"></ZxFawuZhixingjinduForm>
    </el-dialog>
    <!-- 执行回款 增加 -->
    <el-dialog width="700px" title="新增执行回款信息" :visible.sync="zxZhixinghuikuan_isShow" :close-on-click-modal="false">
      <ZxFawuZhixinghuikuanForm
        ref="fawuZhixinghuikuanForm"
        @submitForm="submitZhixinghuikuanForm"
        @resetForm="resetZhixinghuikuanForm"
      ></ZxFawuZhixinghuikuanForm>
    </el-dialog>

    <div id="main">
      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">一. 基本情况</div>
              <div>
                <el-button type="warning" @click="backList">返 回</el-button>
              </div>
            </div>
            <contractdetail-table :contractDetail="contractDetail" :userType="usertype"></contractdetail-table>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">二. 回款情况</div>
            </div>
            <HtFawuHuikuanTable :userType="usertype" :tableData="fawuHuikuanTableData"></HtFawuHuikuanTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">三. 催收情况</div>
            </div>
            <HtFawuCuishouTable :userType="usertype" :tableData="fawuCuishouTableData"></HtFawuCuishouTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">四. 诉讼情况</div>
            </div>
            <HtFawuSusongTable :userType="usertype" :tableData="fawuSusongTableData"></HtFawuSusongTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">法务合同备注</div>
            </div>
            <el-input type="textarea" :rows="3" v-model="ht_remark" readonly></el-input>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">五. 执行情况</div>
            </div>
            <HtFawuZhixingTable :userType="usertype" :tableData="fawuZhixingTableData"></HtFawuZhixingTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div>
            <div class="block_header">
              <div class="block_title_zx">5.1 判决情况</div>
              <div>
                <el-button type="primary" @click="addPanjue">+ 新增</el-button>
              </div>
            </div>
            <ZxFawuPanjueTable :userType="usertype" :tableData="fawuPanjueTableData" @del="deletePanjue"></ZxFawuPanjueTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div>
            <div class="block_header">
              <div class="block_title_zx">5.2 被执行人情况</div>
              <div>
                <el-button type="primary" @click="addBeizhixingren">+ 新增</el-button>
              </div>
            </div>
            <ZxFawuBeizhixingrenTable :userType="usertype" :tableData="fawuBeizhixingrenTableData" @del="deleteBeizhixingren"></ZxFawuBeizhixingrenTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div>
            <div class="block_header">
              <div class="block_title_zx">5.3 执行进度</div>
              <div>
                <el-button type="primary" @click="addFawuzhixingjindu">+ 新增</el-button>
              </div>
            </div>
            <ZxFawuZhixingjinduTable :userType="usertype" :tableData="fawuZhixingjinduTableData" @del="deleteFawuzhixingjindu"></ZxFawuZhixingjinduTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div>
            <div class="block_header">
              <div class="block_title_zx">5.4 执行回款</div>
              <div>
                <el-button type="primary" @click="addZhixinghuikuan">+ 新增</el-button>
              </div>
            </div>
            <ZxFawuZhixinghuikuanTable :userType="usertype" :tableData="fawuZhixinghuikuanTableData" @del="deleteZhixinghuikuan"></ZxFawuZhixinghuikuanTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div>
            <div class="block_header">
              <div class="block_title_zx">5.5 备注</div>
              <div>
                <el-button type="primary" @click="remark_readonly = false">可修改</el-button>
                <el-button type="primary" @click="saveRemark" :disabled="remark_readonly">保 存</el-button>
              </div>
            </div>
            <el-input type="textarea" :rows="4" v-model="remark" :readonly="remark_readonly"></el-input>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HtFawuHuikuanTable from '../FawuHtManage/HtFawuHuikuanTable'
import HtFawuCuishouTable from '../FawuHtManage/HtFawuCuishouTable'
import HtFawuSusongTable from '../FawuHtManage/HtFawuSusongTable'
import HtFawuZhixingTable from '../FawuHtManage/HtFawuZhixingTable'

import ContractdetailTable from './ZxFawuContractDetailTable'
import ZxFawuPanjueTable from './ZxFawuPanjueTable'
import ZxFawuPanjueForm from './ZxFawuPanjueForm'
import ZxFawuBeizhixingrenTable from './ZxFawuBeizhixingrenTable'
import ZxFawuBeizhixingrenForm from './ZxFawuBeizhixingrenForm'
import ZxFawuZhixingjinduTable from './ZxFawuZhixingjinduTable'
import ZxFawuZhixingjinduForm from './ZxFawuZhixingjinduForm'
import ZxFawuZhixinghuikuanTable from './ZxFawuZhixinghuikuanTable'
import ZxFawuZhixinghuikuanForm from './ZxFawuZhixinghuikuanForm'

import utils from '../../assets/js/utils'

export default {
  data() {
    return {
      currentContract: '',
      contractDetail: {},

      fawuHuikuanTableData: [],
      fawuCuishouTableData: [],
      fawuSusongTableData: [],
      fawuZhixingTableData: [],

      fawuPanjueTableData: [],
      zxPanjue_isShow: false,
      fawuPanjueForm: {},
      fawuBeizhixingrenTableData: [],
      zxBeizhixingren_isShow: false,
      fawuBeizhixingrenForm: {},
      fawuZhixingjinduTableData: [],
      zxZhixingjindu_isShow: false,
      fawuZhixingjinduForm: {},
      fawuZhixinghuikuanTableData: [],
      zxZhixinghuikuan_isShow: false,
      fawuZhixinghuikuanForm: {},
      usertype: '',
      user_realname: '',
      ht_remark: '',
      remark: '',
      remark_readonly: true
    }
  },

  components: {
    ContractdetailTable,

    HtFawuHuikuanTable,
    HtFawuCuishouTable,
    HtFawuSusongTable,
    HtFawuZhixingTable,

    ZxFawuPanjueTable,
    ZxFawuPanjueForm,
    ZxFawuBeizhixingrenTable,
    ZxFawuBeizhixingrenForm,
    ZxFawuZhixingjinduTable,
    ZxFawuZhixingjinduForm,
    ZxFawuZhixinghuikuanTable,
    ZxFawuZhixinghuikuanForm
  },
  methods: {
    //返回合同列表页
    backList() {
      this.$router.push({ name: 'ZxFawuManage', query: { from: 'detail' } })
    },
    //获取合同详情
    getContractDetail() {
      this.currentContract = this.$route.query.id
      console.log('contract_id:', this.currentContract)
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('user:', user)
      const api = '/getFwZxContractDetail/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('getFwZxContractDetail', res.data)
          this.contractDetail = res.data
          this.remark = res.data.remark_zx
          this.ht_remark = res.data.remark
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    /*
     ************回款*************
     */
    //获取回款情况
    getFawuhuikuan() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawuhuikuan/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawuhuikuan', res.data.data)
            this.fawuHuikuanTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuHuikuanTableData = []
            console.log('回款信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    /*
     ************催收*************
     */
    //获取催收情况
    getFawucuishou() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawucuishou/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawucuishou', res.data.data)
            this.fawuCuishouTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuCuishouTableData = []
            console.log('催收信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    /*
     ************诉讼*************
     */
    //获取诉讼情况
    getFawususong() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawususong/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawususong', res.data.data)
            this.fawuSusongTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuSusongTableData = []
            console.log('诉讼信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    /*
     ************执行*************
     */
    //获取执行情况
    getFawuzhixing() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawuzhixing/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawuzhixing', res.data.data)
            this.fawuZhixingTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuZhixingTableData = []
            console.log('执行信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    /*
     ************判决*************
     */
    //获取判决情况
    getPanjue() {
      console.log('contract_id:', this.currentContract)
      const api = '/getPanjue/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getPanjue', res.data.data)
            this.fawuPanjueTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuPanjueTableData = []
            console.log('判决信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增判决信息
    addPanjue() {
      this.ZxFawuPanjueForm = {}
      this.zxPanjue_isShow = true
    },

    //删除判决信息
    deletePanjue(row) {
      this.$confirm('此操作将永久删除此判决信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deletePanjue/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getPanjue()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitPanjueForm(formData) {
      this.fawuPanjueForm = formData
      //增加法务回款
      console.log('add fawuPanjueForm:', this.fawuPanjueForm)
      this.fawuPanjueForm.contract = this.currentContract
      this.fawuPanjueForm.added_person = this.user_realname
      this.fawuPanjueForm.added_person_time = utils.getDateTime()
      this.$axios.post('/addPanjue/', this.fawuPanjueForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加判决信息成功!',
            duration: 2000
          })
          this.zxPanjue_isShow = false
          this.getPanjue()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetPanjueForm() {
      this.zxPanjue_isShow = false
      this.fawuPanjueForm = {}
    },

    /*
     ************被执行人*************
     */
    //获取被执行人情况
    getBeizhixingren() {
      console.log('contract_id:', this.currentContract)
      const api = '/getBeizhixingren/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getBeizhixingren', res.data.data)
            this.fawuBeizhixingrenTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuBeizhixingrenTableData = []
            console.log('被执行人信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增被执行人信息
    addBeizhixingren() {
      this.fawuBeizhixingrenForm = {}
      this.zxBeizhixingren_isShow = true
    },

    //删除被执行人信息
    deleteBeizhixingren(row) {
      this.$confirm('此操作将永久删除此被执行人信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteBeizhixingren/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getBeizhixingren()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitBeizhixingrenForm(formData) {
      this.fawuBeizhixingrenForm = formData
      //增加被执行人
      console.log('add fawuBeizhixingrenForm:', this.fawuBeizhixingrenForm)
      this.fawuBeizhixingrenForm.contract = this.currentContract
      this.fawuBeizhixingrenForm.added_person = this.user_realname
      this.fawuBeizhixingrenForm.added_person_time = utils.getDateTime()
      this.$axios.post('/addBeizhixingren/', this.fawuBeizhixingrenForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加被执行人信息成功!',
            duration: 2000
          })
          this.zxBeizhixingren_isShow = false
          this.getBeizhixingren()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetBeizhixingrenForm() {
      this.zxBeizhixingren_isShow = false
      this.fawuBeizhixingrenForm = {}
    },

    /*
     ************执行进度*************
     */
    //获取执行进度情况
    getFawuzhixingjindu() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawuzhixingjindu/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawuzhixingjindu', res.data.data)
            this.fawuZhixingjinduTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuZhixingjinduTableData = []
            console.log('执行进度信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增执行进度信息
    addFawuzhixingjindu() {
      this.fawuZhixingjinduForm = {}
      this.zxZhixingjindu_isShow = true
    },

    //删除执行进度信息
    deleteFawuzhixingjindu(row) {
      this.$confirm('此操作将永久删除此执行进度信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteFawuzhixingjindu/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getFawuzhixingjindu()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitZhixingjinduForm(formData) {
      this.fawuZhixingjinduForm = formData
      //增加执行进度
      console.log('add fawuZhixingjinduForm:', this.fawuZhixingjinduForm)
      this.fawuZhixingjinduForm.contract = this.currentContract
      this.fawuZhixingjinduForm.added_person = this.user_realname
      this.fawuZhixingjinduForm.added_person_time = utils.getDateTime()
      this.$axios.post('/addFawuzhixingjindu/', this.fawuZhixingjinduForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加执行进度信息成功!',
            duration: 2000
          })
          this.zxZhixingjindu_isShow = false
          this.getFawuzhixingjindu()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetZhixingjinduForm() {
      this.zxZhixingjindu_isShow = false
      this.fawuZhixingjinduForm = {}
    },

    /*
     ************执行回款*************
     */
    //获取执行回款情况
    getZhixinghuikuan() {
      console.log('contract_id:', this.currentContract)
      const api = '/getZhixinghuikuan/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getZhixinghuikuan', res.data.data)
            this.fawuZhixinghuikuanTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            this.fawuZhixinghuikuanTableData = []
            console.log('执行信息回款为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增执行回款信息
    addZhixinghuikuan() {
      this.fawuZhixinghuikuanForm = {}
      this.zxZhixinghuikuan_isShow = true
    },

    //删除执行回款
    deleteZhixinghuikuan(row) {
      this.$confirm('此操作将永久删除此执行回款信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteZhixinghuikuan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getZhixinghuikuan()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitZhixinghuikuanForm(formData) {
      this.fawuZhixinghuikuanForm = formData
      //增加执行回款
      console.log('add fawuZhixinghuikuanForm:', this.fawuZhixinghuikuanForm)
      this.fawuZhixinghuikuanForm.contract = this.currentContract
      this.fawuZhixinghuikuanForm.added_person = this.user_realname
      this.fawuZhixinghuikuanForm.added_person_time = utils.getDateTime()
      this.$axios.post('/addZhixinghuikuan/', this.fawuZhixinghuikuanForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加执行信息成功!',
            duration: 2000
          })
          this.zxZhixinghuikuan_isShow = false
          this.getZhixinghuikuan()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetZhixinghuikuanForm() {
      this.zxZhixinghuikuan_isShow = false
      this.fawuZhixinghuikuanForm = {}
    },

    saveRemark() {
      this.$confirm('确认要修改备注吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/UpdateFawuZxcontract/', {
              params: {
                contract_id: this.currentContract,
                remark: this.remark
              }
            })
            .then(res => {
              console.log(res.data)
              this.remark_readonly = true
              this.$message({
                type: 'success',
                message: '修改成功!'
              })
            })
        })
        .catch(() => {
          this.remark_readonly = true
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  },
  created() {
    this.getContractDetail()
    this.getFawuhuikuan()
    this.getFawucuishou()
    this.getFawususong()
    this.getPanjue()
    this.getBeizhixingren()
    this.getFawuzhixingjindu()
    this.getZhixinghuikuan()
  }
}
</script>
<style scoped>
#container {
  width: 100%;
  /* padding: 10px; */
  background-color: rgb(238, 240, 245);
}
.block_title {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(142, 192, 201);
  font-size: 15px;
  color: white;
}
.block_title_zx {
  height: 30px;
  width: 150px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(126, 161, 167);
  font-size: 15px;
  color: white;
}

.block_header {
  display: flex;
  justify-content: space-between;
}

.block {
  width: 100%;
  background-color: rgb(142, 192, 201);
  margin-bottom: 20px;
}
.block_zx {
  width: 100%;
  background-color: rgb(126, 161, 167);
  margin-bottom: 20px;
}
</style>
<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
