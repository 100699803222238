<template>
  <div>
    <div>
      <el-table
        :data="tableData"
        border
        stripe
        :header-row-style="TableHeaderRowStyle"
        :header-cell-style="TableHeaderCellStyle"
        :row-style="TableRowStyle"
        :cell-style="TableCellStyle"
      >
        <el-table-column
          align="center"
          prop="cuishou_date"
          label="日期"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="cuishou_qingkuang"
          label="催收情况"
          min-width="50%"
        >
        </el-table-column>

        <el-table-column align="center" label="操作" min-width="30%">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              @click="handleEdit(scope.row)"
              v-if="userType.code === '3'"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              v-if="canDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  props: {
    tableData: Array,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.id === 1) return true
        else {
          let added_person_time = utils.stringToDate(row.added_person_time)
          let now = new Date()
          let interval = now.getTime() - added_person_time.getTime()
          interval = interval / 1000 / 60 / 60
          console.log('addedtime:', added_person_time)
          console.log('now:', now)
          console.log('del..........', interval)
          if (interval > 2) return false
          else return true
        }
      }
    }
  },
  methods: {
    //设置表格行的样式
    //如果要设置不同的行不同风格，则通过{row,rowIndex}设置， tableRowStyle({row,rowIndex})
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },
    //修改
    handleEdit(row) {
      console.log('cuishou row:', row)
      this.$emit('edit', row)
    }
  }
}
</script>
